@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');

.sidebar {
  width: 252px;
  height: 100vh;
  border-right: 1px solid #1c1c1c19;
  position: absolute;
  overflow: hidden;
  box-shadow:
    0 2px 4px 0 rgba(0, 0, 0, 0.13),
    0 1px 1px 0 rgba(0, 0, 0, 0.11);
  transition: all 1s ease;

}

.brand {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px;
}

.brand .name {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-left: 5px;
}

.header {
  height: 68px;
  padding: 20px 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #1c1c1c19;
  box-shadow:
    0 2px 4px 0 rgba(0, 0, 0, 0.13),
    0 1px 1px 0 rgba(0, 0, 0, 0.11);
}

.header-left {
  display: flex;
  align-items: center;
}

.breadcrumb-item-inactive-link {
  color: rgba(28, 28, 28, 0.4) !important;
}

.topbar-search-input:hover {
  border: none;
  outline: none;
}

.menu-item {
  color: #000000;
}

/*.menu-item:hover{*/
/*    color: #fff;*/
/*    background-color: #ffb1c18a;*/
/*}*/
/*.menu-item:hover > svg{*/
/*    fill:#fff !important;*/
/*}*/

.fof {
  height: 100vh;
  width: 100vw;
  display: flex;
  color: #6c3b1c;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.fof h1 {
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
}

.upload-circle {
  width: 100px;
  height: 100px;
  animation-name: spin;
  animation-duration: 20000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9ad0f5;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #87c1e7;
}